import camelcaseKeys from 'camelcase-keys';
import {
  ADDRESS_ADD_ERROR,
  ADDRESS_ADD_START,
  ADDRESS_ADD_SUCCESS,
  ADDRESS_EDIT_ERROR,
  ADDRESS_EDIT_START,
  ADDRESS_EDIT_SUCCESS,
  ADDRESS_LIST_FETCH_ERROR,
  ADDRESS_LIST_FETCH_START,
  ADDRESS_LIST_FETCH_SUCCESS,
} from '../ActionTypes';

const initState = {
  addressList: null,
  loading: false,
  error: '',
};

const addressReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case ADDRESS_LIST_FETCH_START:
    case ADDRESS_ADD_START:
    case ADDRESS_EDIT_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case ADDRESS_LIST_FETCH_ERROR:
    case ADDRESS_ADD_ERROR:
    case ADDRESS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADDRESS_LIST_FETCH_SUCCESS:
      return {
        ...state,
        addressList: payload,
        loading: false,
        error: '',
      };

    case ADDRESS_ADD_SUCCESS:
      return {
        ...state,
        addressList: state.addressList ? [...state.addressList, payload] : [payload],
        loading: false,
        error: '',
      };

    case ADDRESS_EDIT_SUCCESS:
      return {
        ...state,
        addressList: state.addressList
          ? state.addressList.map((address) => (address.id === payload.id ? payload : address))
          : [payload],
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default addressReducer;
