import camelcaseKeys from 'camelcase-keys';
import {
  CLEAR_TENANT,
  TENANT_LIST_FETCH_ERROR,
  TENANT_LIST_FETCH_START,
  TENANT_LIST_FETCH_SUCCESS,
  TENANT_SELECT_ERROR,
  TENANT_SELECT_START,
  TENANT_SELECT_SUCCESS,
} from '../ActionTypes';

const initState = {
  agentTenantList: null,
  agentTenantDetail: null,
  cleanerTenantList: null,
  cleanerTenantDetail: null,
  loading: false,
  error: '',
};

const tenantReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case TENANT_LIST_FETCH_START:
    case TENANT_SELECT_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case TENANT_LIST_FETCH_ERROR:
    case TENANT_SELECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TENANT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}TenantList`]: payload.data,
        [`${payload.tenantType.toLowerCase()}TenantDetail`]:
          payload && payload.data ? payload.data.find((tenant) => tenant.tenantAccess.selected) : {},
        loading: false,
        error: '',
      };

    case TENANT_SELECT_SUCCESS:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}TenantList`]:
          payload && payload.data
            ? state[`${payload.tenantType.toLowerCase()}TenantList`].map((tenant) =>
                tenant.id === payload.data.id ? payload.data : tenant,
              )
            : [],
        [`${payload.tenantType.toLowerCase()}TenantDetail`]: payload.data,
        loading: false,
        error: '',
      };

    case CLEAR_TENANT:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}TenantList`]: null,
        [`${payload.tenantType.toLowerCase()}TenantDetail`]: null,
      };

    default:
      return state;
  }
};

export default tenantReducer;
