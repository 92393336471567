import {
  SET_ACTIVE_PORTAL,
  USER_PROFILE_GET_DETAIL_ERROR,
  USER_PROFILE_GET_DETAIL_START,
  USER_PROFILE_GET_DETAIL_SUCCESS,
} from '../ActionTypes';
import camelcaseKeys from 'camelcase-keys';

const initState = {
  activePortal: 'client', // agent, cleaner
  userProfile: null,
};

const profileReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case SET_ACTIVE_PORTAL:
      return {
        ...state,
        activePortal: action.payload,
      };

    case USER_PROFILE_GET_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case USER_PROFILE_GET_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_PROFILE_GET_DETAIL_SUCCESS:
      return {
        ...state,
        userProfile: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default profileReducer;
