import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';

const Core = ({children}) => {
  const router = useRouter();

  const handleRouteChange = (url) => {
    window.gtag('config', 'G-7H9FW99TKK', {
      page_path: url,
    });
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <>{children}</>;
};

export default Core;

Core.propTypes = {
  children: PropTypes.node.isRequired,
};
