import commonReducer from './commonReducer';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import brandReducer from './brandReducer';
import productPopularReducer from './productPopularReducer';
import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer';
import compareReducer from './compareReducer';
import leadReducer from './leadReducer';
import directOrderReducer from './directOrderReducer';
import customerReducer from './customerReducer';
import addressReducer from './addressReducer';
import driverReducer from './driverReducer';
import orderTaskReducer from './orderTaskReducer';
import profileReducer from './profileReducer';
import {combineReducers} from 'redux';
import tenantReducer from './tenantReducer';
import cleanerLocationReducer from './cleanerLocationReducer';

const rootReducer = combineReducers({
  commonData: commonReducer,
  productData: productReducer,
  categoryData: categoryReducer,
  brandData: brandReducer,
  productPopularData: productPopularReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  leadData: leadReducer,
  directOrderData: directOrderReducer,
  customerData: customerReducer,
  addressData: addressReducer,
  driverData: driverReducer,
  orderTaskData: orderTaskReducer,
  profileData: profileReducer,
  tenantData: tenantReducer,
  cleanerLocationData: cleanerLocationReducer,
});

export default rootReducer;
