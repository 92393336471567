import camelcaseKeys from 'camelcase-keys';

import {LEAD_ADD_ERROR, LEAD_ADD_START, LEAD_ADD_SUCCESS} from '../ActionTypes';

const initState = {
  lead: null,
  loading: false,
  error: '',
};

const leadReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case LEAD_ADD_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case LEAD_ADD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LEAD_ADD_SUCCESS:
      return {
        ...state,
        lead: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default leadReducer;
