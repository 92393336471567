import {
  FETCH_ERROR,
  FETCH_ERROR_SPINNER,
  FETCH_START,
  FETCH_START_SPINNER,
  FETCH_SUCCESS,
  FETCH_SUCCESS_SPINNER,
  HIDE_MESSAGE,
  HIDE_SPINNER,
  SHOW_MESSAGE,
  SHOW_SPINNER,
} from '../ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  spinner: false,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SPINNER: {
      return {
        ...state,
        spinner: true,
      };
    }

    case HIDE_SPINNER: {
      return {
        ...state,
        spinner: false,
      };
    }

    case FETCH_START: {
      return {
        ...state,
        loading: true,
        error: '',
        message: '',
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
      };
    }

    case FETCH_START_SPINNER: {
      return {
        ...state,
        loading: true,
        error: '',
        message: '',
        spinner: true,
      };
    }

    case FETCH_SUCCESS_SPINNER: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        spinner: false,
      };
    }

    case FETCH_ERROR_SPINNER: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        spinner: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.payload,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        error: '',
        message: '',
      };
    }

    default:
      return state;
  }
};
export default commonReducer;
