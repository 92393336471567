import * as Sentry from '@sentry/nextjs';

const DEFAULT_SENTRY_DSN =
  'https://b23250ec13cee0f657bfe60e3eca99d8@o4505766930219008.ingest.sentry.io/4505766934872064';
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || DEFAULT_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Replay may only be enabled for the client-side
  // integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: new URL(process.env.NEXT_PUBLIC_URL).host,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
