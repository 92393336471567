import camelcaseKeys from 'camelcase-keys';
import {
  CATEGORY_DETAIL_CLEAR_SUCCESS,
  CATEGORY_DETAIL_FETCH_ERROR,
  CATEGORY_DETAIL_FETCH_START,
  CATEGORY_DETAIL_FETCH_SUCCESS,
  CATEGORY_LIST_FETCH_ERROR,
  CATEGORY_LIST_FETCH_START,
  CATEGORY_LIST_FETCH_SUCCESS,
} from '../ActionTypes';

const initState = {
  categoryList: [],
  category: null,
  loading: false,
  error: '',
};

const categoryReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case CATEGORY_LIST_FETCH_START:
    case CATEGORY_DETAIL_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case CATEGORY_LIST_FETCH_ERROR:
    case CATEGORY_DETAIL_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CATEGORY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        categoryList: payload,
        loading: false,
        error: '',
      };

    case CATEGORY_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        category: payload,
        loading: false,
        error: '',
      };

    case CATEGORY_DETAIL_CLEAR_SUCCESS:
      return {
        ...state,
        category: '',
      };

    default:
      return state;
  }
};

export default categoryReducer;
