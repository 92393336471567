import {applyMiddleware, createStore} from 'redux';
import {createWrapper, HYDRATE} from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import {persistStore, persistReducer} from 'redux-persist';
import {createWhitelistFilter} from 'redux-persist-transform-filter';

// https://github.com/vercel/next.js/discussions/15687
const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

// https://github.com/kirill-konshin/next-redux-wrapper
// https://www.npmjs.com/package/redux-persist
// https://github.com/vercel/next.js/blob/canary/examples/with-redux-persist/store.js

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['cartData', 'wishlistData', 'compareData', 'directOrderData', 'profileData'],
  transforms: [createWhitelistFilter('directOrderData', ['directOrder'])],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const {composeWithDevTools} = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    // This data we take from the server props
    // commonData
    // productData
    // categoryData
    // brandData
    // productPopularData
    // directOrderData

    // This data we take from the client state
    nextState.cartData = state.cartData;
    nextState.wishlistData = state.wishlistData;
    nextState.compareData = state.compareData;
    nextState.leadData = state.leadData;
    nextState.directOrderData = state.directOrderData;
    nextState.profileData = state.profileData;

    // https://stackoverflow.com/questions/69781714/next-js-with-next-redux-wrapper-state-is-being-reset-to-initial-value-when-navig
    // if (state.address.id) {
    //   nextState.address = state.address;
    // }
    return nextState;
  } else {
    return persistedReducer(state, action);
  }
};

const store = createStore(reducer, bindMiddleware([thunkMiddleware]));

const initStore = () => {
  store.__persistor = persistStore(store); // Nasty hack
  return store;
};

export const wrapper = createWrapper(initStore);
