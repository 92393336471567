// API
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

export const FETCH_START_SPINNER = 'FETCH_START_SPINNER';
export const FETCH_SUCCESS_SPINNER = 'FETCH_SUCCESS_SPINNER';
export const FETCH_ERROR_SPINNER = 'FETCH_ERROR_SPINNER';

// COMMON
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

// LEAD
export const LEAD_ADD_START = 'LEAD_ADD_START';
export const LEAD_ADD_SUCCESS = 'LEAD_ADD_SUCCESS';
export const LEAD_ADD_ERROR = 'LEAD_ADD_ERROR';

// PRODUCTS
export const PRODUCT_LIST_REFRESH_PROPS = 'PRODUCT_LIST_REFRESH_PROPS';

export const PRODUCT_LIST_FETCH_START = 'PRODUCT_LIST_FETCH_START';
export const PRODUCT_LIST_FETCH_SUCCESS = 'PRODUCT_LIST_FETCH_SUCCESS';
export const PRODUCT_LIST_FETCH_ERROR = 'PRODUCT_LIST_FETCH_ERROR';

export const PRODUCT_DETAIL_FETCH_START = 'PRODUCT_DETAIL_FETCH_START';
export const PRODUCT_DETAIL_FETCH_SUCCESS = 'PRODUCT_DETAIL_FETCH_SUCCESS';
export const PRODUCT_DETAIL_FETCH_ERROR = 'PRODUCT_DETAIL_FETCH_ERROR';
export const PRODUCT_DETAIL_CLEAR_SUCCESS = 'PRODUCT_DETAIL_CLEAR_SUCCESS';

export const PRODUCT_KEYWORD_SAVE_TITLE = 'PRODUCT_KEYWORD_SAVE_TITLE';
export const PRODUCT_KEYWORD_CLEAR_TITLE = 'PRODUCT_KEYWORD_CLEAR_TITLE';

export const PRODUCT_LIST_POPULAR_FETCH_START = 'PRODUCT_LIST_POPULAR_FETCH_START';
export const PRODUCT_LIST_POPULAR_FETCH_SUCCESS = 'PRODUCT_LIST_POPULAR_FETCH_SUCCESS';
export const PRODUCT_LIST_POPULAR_FETCH_ERROR = 'PRODUCT_LIST_POPULAR_FETCH_ERROR';
export const PRODUCT_LIST_CLEAN = 'PRODUCT_LIST_CLEAN';

export const PRODUCT_COLORS_FETCH_START = 'PRODUCT_COLORS_FETCH_START';
export const PRODUCT_COLORS_FETCH_SUCCESS = 'PRODUCT_COLORS_FETCH_SUCCESS';
export const PRODUCT_COLORS_FETCH_ERROR = 'PRODUCT_COLORS_FETCH_ERROR';

export const PRODUCT_SIZES_FETCH_START = 'PRODUCT_SIZES_FETCH_START';
export const PRODUCT_SIZES_FETCH_SUCCESS = 'PRODUCT_SIZES_FETCH_SUCCESS';
export const PRODUCT_SIZES_FETCH_ERROR = 'PRODUCT_SIZES_FETCH_ERROR';

export const PRODUCT_BRANDS_FETCH_START = 'PRODUCT_BRANDS_FETCH_START';
export const PRODUCT_BRANDS_FETCH_SUCCESS = 'PRODUCT_BRANDS_FETCH_SUCCESS';
export const PRODUCT_BRANDS_FETCH_ERROR = 'PRODUCT_BRANDS_FETCH_ERROR';

export const PRODUCT_TAGS_FETCH_START = 'PRODUCT_TAGS_FETCH_START';
export const PRODUCT_TAGS_FETCH_SUCCESS = 'PRODUCT_TAGS_FETCH_SUCCESS';
export const PRODUCT_TAGS_FETCH_ERROR = 'PRODUCT_TAGS_FETCH_ERROR';

export const PRODUCT_UPDATE_MAIN_SKU = 'PRODUCT_UPDATE_MAIN_SKU';
export const PRODUCT_UPDATE_PRICE = 'PRODUCT_UPDATE_PRICE';

export const DIRECT_ORDER_CREATE_START = 'DIRECT_ORDER_CREATE_START';
export const DIRECT_ORDER_CREATE_SUCCESS = 'DIRECT_ORDER_CREATE_SUCCESS';
export const DIRECT_ORDER_CREATE_ERROR = 'DIRECT_ORDER_CREATE_ERROR';

export const DIRECT_ORDER_FETCH_START = 'DIRECT_ORDER_FETCH_START';
export const DIRECT_ORDER_FETCH_SUCCESS = 'DIRECT_ORDER_FETCH_SUCCESS';
export const DIRECT_ORDER_FETCH_ERROR = 'DIRECT_ORDER_FETCH_ERROR';
export const DIRECT_ORDER_CLEAN = 'DIRECT_ORDER_CLEAN';

export const DIRECT_ORDER_FETCH_DETAIL_START = 'DIRECT_ORDER_FETCH_DETAIL_START';
export const DIRECT_ORDER_FETCH_DETAIL_SUCCESS = 'DIRECT_ORDER_FETCH_DETAIL_SUCCESS';
export const DIRECT_ORDER_FETCH_DETAIL_ERROR = 'DIRECT_ORDER_FETCH_DETAIL_ERROR';
export const DIRECT_ORDER_DETAIL_CLEAN = 'DIRECT_ORDER_DETAIL_CLEAN';

export const DIRECT_ORDER_FETCH_LIST_START = 'DIRECT_ORDER_FETCH_LIST_START';
export const DIRECT_ORDER_FETCH_LIST_SUCCESS = 'DIRECT_ORDER_FETCH_LIST_SUCCESS';
export const DIRECT_ORDER_FETCH_LIST_ERROR = 'DIRECT_ORDER_FETCH_LIST_ERROR';

export const DIRECT_ORDER_PAY_START = 'DIRECT_ORDER_PAY_START';
export const DIRECT_ORDER_PAY_SUCCESS = 'DIRECT_ORDER_PAY_SUCCESS';
export const DIRECT_ORDER_PAY_ERROR = 'DIRECT_ORDER_PAY_ERROR';

// CATEGORIES
export const CATEGORY_LIST_FETCH_START = 'CATEGORY_LIST_FETCH_START';
export const CATEGORY_LIST_FETCH_SUCCESS = 'CATEGORY_LIST_FETCH_SUCCESS';
export const CATEGORY_LIST_FETCH_ERROR = 'CATEGORY_LIST_FETCH_ERROR';

export const CATEGORY_DETAIL_FETCH_START = 'CATEGORY_DETAIL_FETCH_START';
export const CATEGORY_DETAIL_FETCH_SUCCESS = 'CATEGORY_DETAIL_FETCH_SUCCESS';
export const CATEGORY_DETAIL_FETCH_ERROR = 'CATEGORY_DETAIL_FETCH_ERROR';
export const CATEGORY_DETAIL_CLEAR_SUCCESS = 'CATEGORY_DETAIL_CLEAR_SUCCESS';

// BRANDS
export const BRAND_LIST_FETCH_START = 'BRAND_LIST_FETCH_START';
export const BRAND_LIST_FETCH_SUCCESS = 'BRAND_LIST_FETCH_SUCCESS';
export const BRAND_LIST_FETCH_ERROR = 'BRAND_LIST_FETCH_ERROR';

// CUSTOMERS
export const CUSTOMER_LIST_FETCH_START = 'CUSTOMER_LIST_FETCH_START';
export const CUSTOMER_LIST_FETCH_SUCCESS = 'CUSTOMER_LIST_FETCH_SUCCESS';
export const CUSTOMER_LIST_FETCH_ERROR = 'CUSTOMER_LIST_FETCH_ERROR';

export const CUSTOMER_ADD_START = 'CUSTOMER_ADD_START';
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS';
export const CUSTOMER_ADD_ERROR = 'CUSTOMER_ADD_ERROR';

export const CUSTOMER_EDIT_START = 'CUSTOMER_EDIT_START';
export const CUSTOMER_EDIT_SUCCESS = 'CUSTOMER_EDIT_SUCCESS';
export const CUSTOMER_EDIT_ERROR = 'CUSTOMER_EDIT_ERROR';

// ADDRESSES
export const ADDRESS_LIST_FETCH_START = 'ADDRESS_LIST_FETCH_START';
export const ADDRESS_LIST_FETCH_SUCCESS = 'ADDRESS_LIST_FETCH_SUCCESS';
export const ADDRESS_LIST_FETCH_ERROR = 'ADDRESS_LIST_FETCH_ERROR';

export const ADDRESS_ADD_START = 'ADDRESS_ADD_START';
export const ADDRESS_ADD_SUCCESS = 'ADDRESS_ADD_SUCCESS';
export const ADDRESS_ADD_ERROR = 'ADDRESS_ADD_ERROR';

export const ADDRESS_EDIT_START = 'ADDRESS_EDIT_START';
export const ADDRESS_EDIT_SUCCESS = 'ADDRESS_EDIT_SUCCESS';
export const ADDRESS_EDIT_ERROR = 'ADDRESS_EDIT_ERROR';

// DRIVER
export const DRIVER_GET_LIST_ALL_START = 'DRIVER_GET_LIST_ALL_START';
export const DRIVER_GET_LIST_ALL_SUCCESS = 'DRIVER_GET_LIST_ALL_SUCCESS';
export const DRIVER_GET_LIST_ALL_ERROR = 'DRIVER_GET_LIST_ALL_ERROR';
export const DRIVER_CLEAR_LIST_ALL = 'DRIVER_CLEAR_LIST_ALL';

export const DRIVER_GET_LIST_START = 'DRIVER_GET_LIST_START';
export const DRIVER_GET_LIST_SUCCESS = 'DRIVER_GET_LIST_SUCCESS';
export const DRIVER_GET_LIST_ERROR = 'DRIVER_GET_LIST_ERROR';
export const DRIVER_CLEAR_LIST = 'DRIVER_CLEAR_LIST';

export const DRIVER_GET_DETAIL_START = 'DRIVER_GET_DETAIL_START';
export const DRIVER_GET_DETAIL_SUCCESS = 'DRIVER_GET_DETAIL_SUCCESS';
export const DRIVER_GET_DETAIL_ERROR = 'DRIVER_GET_DETAIL_ERROR';
export const DRIVER_CLEAR_DETAIL = 'DRIVER_CLEAR_DETAIL';

export const DRIVER_DELETE_DETAIL_START = 'DRIVER_DELETE_DETAIL_START';
export const DRIVER_DELETE_DETAIL_SUCCESS = 'DRIVER_DELETE_DETAIL_SUCCESS';
export const DRIVER_DELETE_DETAIL_ERROR = 'DRIVER_DELETE_DETAIL_ERROR';

export const DRIVER_ADD_DETAIL_START = 'DRIVER_ADD_DETAIL_START';
export const DRIVER_ADD_DETAIL_SUCCESS = 'DRIVER_ADD_DETAIL_SUCCESS';
export const DRIVER_ADD_DETAIL_ERROR = 'DRIVER_ADD_DETAIL_ERROR';

export const DRIVER_EDIT_DETAIL_START = 'DRIVER_EDIT_DETAIL_START';
export const DRIVER_EDIT_DETAIL_SUCCESS = 'DRIVER_EDIT_DETAIL_SUCCESS';
export const DRIVER_EDIT_DETAIL_ERROR = 'DRIVER_EDIT_DETAIL_ERROR';

// CLEANER_LOCATION
export const CLEANER_LOCATION_GET_LIST_ALL_START = 'CLEANER_LOCATION_GET_LIST_ALL_START';
export const CLEANER_LOCATION_GET_LIST_ALL_SUCCESS = 'CLEANER_LOCATION_GET_LIST_ALL_SUCCESS';
export const CLEANER_LOCATION_GET_LIST_ALL_ERROR = 'CLEANER_LOCATION_GET_LIST_ALL_ERROR';
export const CLEANER_LOCATION_CLEAR_LIST_ALL = 'CLEANER_LOCATION_CLEAR_LIST_ALL';

// ORDER_TASK
export const ORDER_TASK_GET_LIST_START = 'ORDER_TASK_GET_LIST_START';
export const ORDER_TASK_GET_LIST_SUCCESS = 'ORDER_TASK_GET_LIST_SUCCESS';
export const ORDER_TASK_GET_LIST_ERROR = 'ORDER_TASK_GET_LIST_ERROR';
export const ORDER_TASK_CLEAR_LIST = 'ORDER_TASK_CLEAR_LIST';

export const ORDER_TASK_ASSIGN_DRIVER_START = 'ORDER_TASK_ASSIGN_DRIVER_START';
export const ORDER_TASK_ASSIGN_DRIVER_SUCCESS = 'ORDER_TASK_ASSIGN_DRIVER_SUCCESS';
export const ORDER_TASK_ASSIGN_DRIVER_ERROR = 'ORDER_TASK_ASSIGN_DRIVER_ERROR';

export const ORDER_TASK_DRIVER_ARRIVED_START = 'ORDER_TASK_DRIVER_ARRIVED_START';
export const ORDER_TASK_DRIVER_ARRIVED_SUCCESS = 'ORDER_TASK_DRIVER_ARRIVED_SUCCESS';
export const ORDER_TASK_DRIVER_ARRIVED_ERROR = 'ORDER_TASK_DRIVER_ARRIVED_ERROR';

export const ORDER_TASK_DRIVER_COMPLETED_TASK_START = 'ORDER_TASK_DRIVER_COMPLETED_TASK_START';
export const ORDER_TASK_DRIVER_COMPLETED_TASK_SUCCESS = 'ORDER_TASK_DRIVER_COMPLETED_TASK_SUCCESS';
export const ORDER_TASK_DRIVER_COMPLETED_TASK_ERROR = 'ORDER_TASK_DRIVER_COMPLETED_TASK_ERROR';

export const ORDER_TASK_LAUNDRY_SERVICE_STARTED_START = 'ORDER_TASK_LAUNDRY_SERVICE_STARTED_START';
export const ORDER_TASK_LAUNDRY_SERVICE_STARTED_SUCCESS = 'ORDER_TASK_LAUNDRY_SERVICE_STARTED_SUCCESS';
export const ORDER_TASK_LAUNDRY_SERVICE_STARTED_ERROR = 'ORDER_TASK_LAUNDRY_SERVICE_STARTED_ERROR';

export const ORDER_TASK_LAUNDRY_SERVICE_ENDED_START = 'ORDER_TASK_LAUNDRY_SERVICE_ENDED_START';
export const ORDER_TASK_LAUNDRY_SERVICE_ENDED_SUCCESS = 'ORDER_TASK_LAUNDRY_SERVICE_ENDED_SUCCESS';
export const ORDER_TASK_LAUNDRY_SERVICE_ENDED_ERROR = 'ORDER_TASK_LAUNDRY_SERVICE_ENDED_ERROR';

export const ORDER_TASK_GET_DETAIL_START = 'ORDER_TASK_GET_DETAIL_START';
export const ORDER_TASK_GET_DETAIL_SUCCESS = 'ORDER_TASK_GET_DETAIL_SUCCESS';
export const ORDER_TASK_GET_DETAIL_ERROR = 'ORDER_TASK_GET_DETAIL_ERROR';
export const ORDER_TASK_CLEAR_DETAIL = 'ORDER_TASK_CLEAR_DETAIL';

export const ORDER_TASK_FETCH_ROUTE_START = 'ORDER_TASK_FETCH_ROUTE_START';
export const ORDER_TASK_FETCH_ROUTE_SUCCESS = 'ORDER_TASK_FETCH_ROUTE_SUCCESS';
export const ORDER_TASK_FETCH_ROUTE_ERROR = 'ORDER_TASK_FETCH_ROUTE_ERROR';

export const ORDER_TASK_ADD_NOTE_TASK_START = 'ORDER_TASK_ADD_NOTE_TASK_START';
export const ORDER_TASK_ADD_NOTE_TASK_SUCCESS = 'ORDER_TASK_ADD_NOTE_TASK_SUCCESS';
export const ORDER_TASK_ADD_NOTE_TASK_ERROR = 'ORDER_TASK_ADD_NOTE_TASK_ERROR';

export const ORDER_TASK_SAVE_TASK_START = 'ORDER_TASK_SAVE_TASK_START';
export const ORDER_TASK_SAVE_TASK_SUCCESS = 'ORDER_TASK_SAVE_TASK_SUCCESS';
export const ORDER_TASK_SAVE_TASK_ERROR = 'ORDER_TASK_SAVE_TASK_ERROR';

// PROFILE
export const SET_ACTIVE_PORTAL = 'SET_ACTIVE_PORTAL';
export const USER_PROFILE_GET_DETAIL_START = 'USER_PROFILE_GET_DETAIL_START';
export const USER_PROFILE_GET_DETAIL_SUCCESS = 'USER_PROFILE_GET_DETAIL_SUCCESS';
export const USER_PROFILE_GET_DETAIL_ERROR = 'USER_PROFILE_GET_DETAIL_ERROR';

// TENANT
export const TENANT_LIST_FETCH_START = 'TENANT_LIST_FETCH_START';
export const TENANT_LIST_FETCH_SUCCESS = 'TENANT_LIST_FETCH_SUCCESS';
export const TENANT_LIST_FETCH_ERROR = 'TENANT_LIST_FETCH_ERROR';

export const TENANT_SELECT_START = 'TENANT_SELECT_START';
export const TENANT_SELECT_SUCCESS = 'TENANT_SELECT_SUCCESS';
export const TENANT_SELECT_ERROR = 'TENANT_SELECT_ERROR';

export const CLEAR_TENANT = 'CLEAR_TENANT';

// SKU
export const SKU_FETCH_QUANTITY_START = 'SKU_FETCH_QUANTITY_START';
export const SKU_FETCH_QUANTITY_SUCCESS = 'SKU_FETCH_QUANTITY_SUCCESS';
export const SKU_FETCH_QUANTITY_ERROR = 'SKU_FETCH_QUANTITY_ERROR';
