import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {hideMessage} from '../../../redux/actions/commonActions';
import {useToasts} from 'react-toast-notifications';

const InfoView = (props) => {
  const dispatch = useDispatch();
  const {error, message} = props.commonData;
  const {addToast} = useToasts();

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    if (error) {
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    if (message || error) {
      dispatch(hideMessage());
    }
  }, [dispatch, addToast, message, error]);

  return (
    <>
      {/*It block the whole screen*/}
      {/*{spinner && <Loader />}*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    commonData: state.commonData,
  };
};

export default connect(mapStateToProps)(InfoView);

InfoView.defaultProps = {};
