import camelcaseKeys from 'camelcase-keys';
import {
  DRIVER_ADD_DETAIL_ERROR,
  DRIVER_ADD_DETAIL_START,
  DRIVER_ADD_DETAIL_SUCCESS,
  DRIVER_CLEAR_DETAIL,
  DRIVER_CLEAR_LIST,
  DRIVER_CLEAR_LIST_ALL,
  DRIVER_DELETE_DETAIL_ERROR,
  DRIVER_DELETE_DETAIL_START,
  DRIVER_DELETE_DETAIL_SUCCESS,
  DRIVER_EDIT_DETAIL_ERROR,
  DRIVER_EDIT_DETAIL_START,
  DRIVER_EDIT_DETAIL_SUCCESS,
  DRIVER_GET_DETAIL_ERROR,
  DRIVER_GET_DETAIL_START,
  DRIVER_GET_DETAIL_SUCCESS,
  DRIVER_GET_LIST_ALL_ERROR,
  DRIVER_GET_LIST_ALL_START,
  DRIVER_GET_LIST_ALL_SUCCESS,
  DRIVER_GET_LIST_ERROR,
  DRIVER_GET_LIST_START,
  DRIVER_GET_LIST_SUCCESS,
} from '../ActionTypes';

const initState = {
  driverListAll: null,
  driverList: null,
  driverListTotal: null,
  driverDetail: null,
  loading: false,
  error: '',
};

const driverReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case DRIVER_GET_LIST_ALL_START:
    case DRIVER_GET_LIST_START:
    case DRIVER_GET_DETAIL_START:
    case DRIVER_DELETE_DETAIL_START:
    case DRIVER_ADD_DETAIL_START:
    case DRIVER_EDIT_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case DRIVER_GET_LIST_ALL_ERROR:
    case DRIVER_GET_LIST_ERROR:
    case DRIVER_GET_DETAIL_ERROR:
    case DRIVER_DELETE_DETAIL_ERROR:
    case DRIVER_ADD_DETAIL_ERROR:
    case DRIVER_EDIT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DRIVER_GET_LIST_ALL_SUCCESS:
      return {
        ...state,
        driverListAll: payload,
        loading: false,
        error: '',
      };

    case DRIVER_CLEAR_LIST_ALL:
      return {
        ...state,
        driverListAll: null,
      };

    case DRIVER_GET_LIST_SUCCESS:
      return {
        ...state,
        driverList: payload.results,
        driverListTotal: payload.count,
        loading: false,
        error: '',
      };

    case DRIVER_CLEAR_LIST:
      return {
        ...state,
        driverList: null,
        driverListTotal: null,
      };

    case DRIVER_GET_DETAIL_SUCCESS:
      return {
        ...state,
        driverDetail: payload,
        loading: false,
        error: '',
      };

    case DRIVER_CLEAR_DETAIL:
      return {
        ...state,
        driverDetail: null,
      };

    case DRIVER_DELETE_DETAIL_SUCCESS:
      return {
        ...state,
        driverDetail: null,
        driverList: state.driverList ? state.driverList.filter((item) => parseInt(payload.id) !== item.id) : null,
        driverListAll: state.driverListAll
          ? state.driverListAll.filter((item) => parseInt(payload.id) !== item.id)
          : null,
        driverListTotal: state.driverListTotal - 1,
      };

    case DRIVER_EDIT_DETAIL_SUCCESS:
      return {
        ...state,
        driverDetail: payload,
        driverList: state.driverList
          ? state.driverList.map((item) => (parseInt(payload.id) === item.id ? payload : item))
          : null,
        driverListAll: state.driverListAll
          ? state.driverListAll.map((item) => (parseInt(payload.id) === item.id ? payload : item))
          : null,
      };

    case DRIVER_ADD_DETAIL_SUCCESS:
      return {
        ...state,
        driverList: state.driverList ? [payload, ...state.driverList] : null,
        driverListAll: state.driverListAll ? [payload, ...state.driverListAll] : null,
      };

    default:
      return state;
  }
};

export default driverReducer;
