import camelcaseKeys from 'camelcase-keys';
import {
  ORDER_TASK_ASSIGN_DRIVER_ERROR,
  ORDER_TASK_ASSIGN_DRIVER_START,
  ORDER_TASK_ASSIGN_DRIVER_SUCCESS,
  ORDER_TASK_CLEAR_DETAIL,
  ORDER_TASK_CLEAR_LIST,
  ORDER_TASK_DRIVER_ARRIVED_ERROR,
  ORDER_TASK_DRIVER_ARRIVED_START,
  ORDER_TASK_DRIVER_ARRIVED_SUCCESS,
  ORDER_TASK_DRIVER_COMPLETED_TASK_ERROR,
  ORDER_TASK_DRIVER_COMPLETED_TASK_START,
  ORDER_TASK_DRIVER_COMPLETED_TASK_SUCCESS,
  ORDER_TASK_GET_DETAIL_ERROR,
  ORDER_TASK_GET_DETAIL_START,
  ORDER_TASK_GET_DETAIL_SUCCESS,
  ORDER_TASK_GET_LIST_ERROR,
  ORDER_TASK_GET_LIST_START,
  ORDER_TASK_GET_LIST_SUCCESS,
  ORDER_TASK_LAUNDRY_SERVICE_ENDED_ERROR,
  ORDER_TASK_LAUNDRY_SERVICE_ENDED_START,
  ORDER_TASK_LAUNDRY_SERVICE_ENDED_SUCCESS,
  ORDER_TASK_LAUNDRY_SERVICE_STARTED_ERROR,
  ORDER_TASK_LAUNDRY_SERVICE_STARTED_START,
  ORDER_TASK_LAUNDRY_SERVICE_STARTED_SUCCESS,
  ORDER_TASK_FETCH_ROUTE_ERROR,
  ORDER_TASK_FETCH_ROUTE_SUCCESS,
  ORDER_TASK_FETCH_ROUTE_START,
  ORDER_TASK_ADD_NOTE_TASK_ERROR,
  ORDER_TASK_ADD_NOTE_TASK_START,
  ORDER_TASK_ADD_NOTE_TASK_SUCCESS,
  ORDER_TASK_SAVE_TASK_START,
  ORDER_TASK_SAVE_TASK_ERROR,
  ORDER_TASK_SAVE_TASK_SUCCESS,
} from '../ActionTypes';
import {orderTaskStatusDict} from '../../config/laundryOrderConfig';

const initState = {
  agentOrderTaskList: null,
  agentOrderTaskCount: null,
  agentOrderTaskDetail: null,
  cleanerOrderTaskList: null,
  cleanerOrderTaskCount: null,
  cleanerOrderTaskDetail: null,
  loading: false,
  error: '',
  orderTaskRoutes: null,
};

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

const orderTaskReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case ORDER_TASK_GET_LIST_START:
    case ORDER_TASK_GET_DETAIL_START:
    case ORDER_TASK_ASSIGN_DRIVER_START:
    case ORDER_TASK_DRIVER_ARRIVED_START:
    case ORDER_TASK_LAUNDRY_SERVICE_STARTED_START:
    case ORDER_TASK_LAUNDRY_SERVICE_ENDED_START:
    case ORDER_TASK_DRIVER_COMPLETED_TASK_START:
    case ORDER_TASK_FETCH_ROUTE_START:
    case ORDER_TASK_ADD_NOTE_TASK_START:
    case ORDER_TASK_SAVE_TASK_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case ORDER_TASK_GET_LIST_ERROR:
    case ORDER_TASK_GET_DETAIL_ERROR:
    case ORDER_TASK_ASSIGN_DRIVER_ERROR:
    case ORDER_TASK_DRIVER_ARRIVED_ERROR:
    case ORDER_TASK_LAUNDRY_SERVICE_STARTED_ERROR:
    case ORDER_TASK_LAUNDRY_SERVICE_ENDED_ERROR:
    case ORDER_TASK_DRIVER_COMPLETED_TASK_ERROR:
    case ORDER_TASK_ADD_NOTE_TASK_ERROR:
    case ORDER_TASK_FETCH_ROUTE_ERROR:
    case ORDER_TASK_SAVE_TASK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORDER_TASK_GET_LIST_SUCCESS:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}OrderTaskList`]: payload.data.results,
        [`${payload.tenantType.toLowerCase()}OrderTaskCount`]: payload.data.count,
        loading: false,
        error: '',
      };

    case ORDER_TASK_CLEAR_LIST:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}OrderTaskList`]: null,
        [`${payload.tenantType.toLowerCase()}OrderTaskCount`]: null,
      };

    case ORDER_TASK_GET_DETAIL_SUCCESS:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}OrderTaskDetail`]: payload.data,
        loading: false,
        error: '',
      };

    case ORDER_TASK_CLEAR_DETAIL:
      return {
        ...state,
        [`${payload.tenantType.toLowerCase()}OrderTaskDetail`]: null,
      };

    case ORDER_TASK_ASSIGN_DRIVER_SUCCESS:
    case ORDER_TASK_DRIVER_ARRIVED_SUCCESS:
    case ORDER_TASK_DRIVER_COMPLETED_TASK_SUCCESS:
    case ORDER_TASK_ADD_NOTE_TASK_SUCCESS:
    case ORDER_TASK_SAVE_TASK_SUCCESS:
      return {
        ...state,
        agentOrderTaskList: state.agentOrderTaskList
          ? state.agentOrderTaskList.map((orderTask) => (orderTask.id === payload.id ? payload : orderTask))
          : null,
        agentOrderTaskDetail: payload,
        cleanerOrderTaskList: state.cleanerOrderTaskList
          ? state.cleanerOrderTaskList.map((orderTask) => (orderTask.id === payload.id ? payload : orderTask))
          : null,
        cleanerOrderTaskDetail: payload,
        loading: false,
        error: null,

        // Update order tasks in routes
        orderTaskRoutes: state.orderTaskRoutes
          ? objectMap(state.orderTaskRoutes, (routes) =>
              routes.map((route) => {
                const orderTasks = route.orderTasks
                  ? route.orderTasks.map((orderTask) =>
                      orderTask.id === payload.id
                        ? {
                            ...orderTask,
                            status: payload.status,
                          }
                        : orderTask,
                    )
                  : [];
                const completedOrderTasks = orderTasks.filter((orderTask) =>
                  [orderTaskStatusDict.PICK_UP_SUCCESSFUL.id, orderTaskStatusDict.DELIVERY_SUCCESSFUL.id].includes(
                    orderTask.status,
                  ),
                );

                return {
                  ...route,
                  orderTasks,
                  finished: completedOrderTasks && orderTasks && completedOrderTasks.length === orderTasks.length,
                };
              }),
            )
          : null,
      };

    case ORDER_TASK_LAUNDRY_SERVICE_STARTED_SUCCESS:
    case ORDER_TASK_LAUNDRY_SERVICE_ENDED_SUCCESS:
      return {
        ...state,
        cleanerOrderTaskList: state.cleanerOrderTaskList
          ? state.cleanerOrderTaskList.map((orderTask) => (orderTask.id === payload.id ? payload : orderTask))
          : null,
        cleanerOrderTaskDetail: payload,
        loading: false,
        error: null,
      };

    case ORDER_TASK_FETCH_ROUTE_SUCCESS:
      return {
        ...state,
        orderTaskRoutes: {...state.orderTaskRoutes, [payload.date]: payload.route},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default orderTaskReducer;
