import camelcaseKeys from 'camelcase-keys';
import {
  CUSTOMER_ADD_ERROR,
  CUSTOMER_ADD_START,
  CUSTOMER_ADD_SUCCESS,
  CUSTOMER_EDIT_ERROR,
  CUSTOMER_EDIT_START,
  CUSTOMER_EDIT_SUCCESS,
  CUSTOMER_LIST_FETCH_ERROR,
  CUSTOMER_LIST_FETCH_START,
  CUSTOMER_LIST_FETCH_SUCCESS,
} from '../ActionTypes';

const initState = {
  customerList: null,
  customerCount: 0,
  loading: false,
  error: '',
};

const customerReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case CUSTOMER_LIST_FETCH_START:
    case CUSTOMER_ADD_START:
    case CUSTOMER_EDIT_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case CUSTOMER_LIST_FETCH_ERROR:
    case CUSTOMER_ADD_ERROR:
    case CUSTOMER_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CUSTOMER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        customerList: payload.results,
        customerCount: payload.count,
        loading: false,
        error: '',
      };

    case CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        customerList: state.customerList ? [...state.customerList, payload] : [payload],
        loading: false,
        error: '',
      };

    case CUSTOMER_EDIT_SUCCESS:
      return {
        ...state,
        customerList: state.customerList
          ? state.customerList.map((customer) => (customer.id === payload.id ? payload : customer))
          : [payload],
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default customerReducer;
