// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : price;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    (single) =>
      single.id === product.id &&
      (single.selectedProductColor ? single.selectedProductColor === color : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true),
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variations) {
      return cartItems.filter(
        (single) =>
          single.id === product.id && single.selectedProductColor === color && single.selectedProductSize === size,
      )[0].quantity;
    } else {
      return cartItems.filter((single) => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll('.grid-icons button');
  gridSwitchBtn.forEach((item) => {
    item.classList.remove('active');
  });
  e.currentTarget.classList.add('active');
};

export const onChangeProductSKU = (product, skuData) => {
  if (!product || !product.sku) {
    return product;
  }

  return {
    ...product,
    // sku: skuData,
    sku: {...product.mainSku, ...skuData},
    // sku: {
    //   ...variation,
    //   size: skuData.size,
    //   size2: skuData.size2,
    //   price: skuData.price,
    //   stock: skuData.stock,
    //   colorProductImageThumbnail: variation.colorProductImageThumbnail
    //     ? variation.colorProductImageThumbnail
    //     : product.sku.colorProductImageThumbnail,
    // },
  };
};

export const reduceProductData = (product) => {
  // Solve the issue:
  // next-dev.js?4ee6:32 Error storing data DOMException: Failed to execute 'setItem' on 'Storage':
  // Setting the value of 'persist:primary' exceeded the quota.
  return {
    id: product.id,
    discount: product.discount,
    title: product.title,
    description: product.description,
    slug: product.slug,
    price: product.price,
    affiliateLink: product.affiliateLink,
    sku: {
      id: product.sku.id,
      price: product.sku.price,
      size: product.sku.size,
      size2: product.sku.size2,
      skuId: product.sku.skuId,
      stock: product.sku.stock,
      description: product.sku.description,
      colorProductImageThumbnail: product.sku.colorProductImageThumbnail,
    },
  };
};
