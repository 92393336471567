export const laundryOrderTypeDict = {
  PICK_UP: {
    id: 'PICK_UP',
    name: 'Pickup',
    past: 'picked up',
  },
  CLEANING: {
    id: 'CLEANING',
    name: 'Cleaning',
    past: 'cleaned',
  },
  DELIVERY: {
    id: 'DELIVERY',
    name: 'Delivery',
    past: 'delivered',
  },
};

export const orderTaskStatusDict = {
  CREATED: {
    id: 'CREATED',
    name: 'Created',
    color: 'grey',
  },
  EDITED: {
    id: 'EDITED',
    name: 'Edited',
    color: 'orange',
  },
  MESSAGE_SENT: {
    id: 'MESSAGE_SENT',
    name: 'Note Received',
    color: '#1395ba',
  },
  AGENT_ASSIGNED: {
    id: 'AGENT_ASSIGNED',
    name: 'Agent Assigned',
    color: 'brown',
  },
  DRIVER_ASSIGNED: {
    id: 'DRIVER_ASSIGNED',
    name: 'Driver Assigned',
    color: 'brown',
  },
  DRIVER_ARRIVED: {
    id: 'DRIVER_ARRIVED',
    name: 'Driver Arrived',
    color: '#3F4CAD',
  },
  PICK_UP_SUCCESSFUL: {
    id: 'PICK_UP_SUCCESSFUL',
    name: 'Pickup Successful',
    color: 'green',
  },
  DELIVERY_SUCCESSFUL: {
    id: 'DELIVERY_SUCCESSFUL',
    name: 'Delivery Successful',
    color: 'green',
  },
  CLEANER_ASSIGNED: {
    id: 'CLEANER_ASSIGNED',
    name: 'Cleaner Assigned',
    color: 'brown',
  },
  CLEANER_LOCATION_ASSIGNED: {
    id: 'CLEANER_LOCATION_ASSIGNED',
    name: 'Cleaner Location Assigned',
    color: 'brown',
  },
  LAUNDRY_SERVICE_STARTED: {
    id: 'LAUNDRY_SERVICE_STARTED',
    name: 'Laundry Service Started',
    color: '#3F4CAD',
  },
  LAUNDRY_SERVICE_ENDED: {
    id: 'LAUNDRY_SERVICE_ENDED',
    name: 'Laundry Service Ended',
    color: 'green',
  },
  NOTE_ADDED: {
    id: 'NOTE_ADDED',
    name: 'Note Added',
    color: 'green',
  },
  SAVED: {
    id: 'SAVED',
    name: 'Saved',
    color: 'green',
  },
};
