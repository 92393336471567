import camelcaseKeys from 'camelcase-keys';
import {
  PRODUCT_LIST_POPULAR_FETCH_ERROR,
  PRODUCT_LIST_POPULAR_FETCH_START,
  PRODUCT_LIST_POPULAR_FETCH_SUCCESS,
} from '../ActionTypes';

const initState = {
  productPopularList: null,
  productPopularCount: 0,
  loading: false,
  error: '',
};

const productPopularReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case PRODUCT_LIST_POPULAR_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case PRODUCT_LIST_POPULAR_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCT_LIST_POPULAR_FETCH_SUCCESS:
      return {
        ...state,
        productPopularList: payload.results,
        productPopularCount: payload.count,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default productPopularReducer;
