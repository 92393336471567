import camelcaseKeys from 'camelcase-keys';

import {
  DIRECT_ORDER_CLEAN,
  DIRECT_ORDER_CREATE_ERROR,
  DIRECT_ORDER_CREATE_START,
  DIRECT_ORDER_CREATE_SUCCESS,
  DIRECT_ORDER_DETAIL_CLEAN,
  DIRECT_ORDER_FETCH_DETAIL_ERROR,
  DIRECT_ORDER_FETCH_DETAIL_START,
  DIRECT_ORDER_FETCH_DETAIL_SUCCESS,
  DIRECT_ORDER_FETCH_ERROR,
  DIRECT_ORDER_FETCH_LIST_ERROR,
  DIRECT_ORDER_FETCH_LIST_START,
  DIRECT_ORDER_FETCH_LIST_SUCCESS,
  DIRECT_ORDER_FETCH_START,
  DIRECT_ORDER_FETCH_SUCCESS,
  DIRECT_ORDER_PAY_ERROR,
  DIRECT_ORDER_PAY_START,
  DIRECT_ORDER_PAY_SUCCESS,
} from '../ActionTypes';

const initState = {
  directOrder: null, // Show in checkout
  directOrderDetail: null, // Show on the page /client-portal/order-D11041/
  directOrderList: null,
  loading: false,
  error: '',
};

const directOrderReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case DIRECT_ORDER_CREATE_START:
    case DIRECT_ORDER_FETCH_START:
    case DIRECT_ORDER_PAY_START:
    case DIRECT_ORDER_FETCH_LIST_START:
    case DIRECT_ORDER_FETCH_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case DIRECT_ORDER_CREATE_ERROR:
    case DIRECT_ORDER_FETCH_ERROR:
    case DIRECT_ORDER_PAY_ERROR:
    case DIRECT_ORDER_FETCH_LIST_ERROR:
    case DIRECT_ORDER_FETCH_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DIRECT_ORDER_CREATE_SUCCESS:
    case DIRECT_ORDER_FETCH_SUCCESS:
    case DIRECT_ORDER_PAY_SUCCESS:
      return {
        ...state,
        directOrder: payload,
        loading: false,
        error: '',
      };

    case DIRECT_ORDER_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        directOrderDetail: payload && payload.length > 0 ? payload[0] : null,
        loading: false,
        error: '',
      };

    case DIRECT_ORDER_CLEAN:
      return {
        ...state,
        directOrder: null,
      };

    case DIRECT_ORDER_DETAIL_CLEAN:
      return {
        ...state,
        directOrderDetail: null,
      };

    case DIRECT_ORDER_FETCH_LIST_SUCCESS:
      return {
        ...state,
        directOrderList: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default directOrderReducer;
