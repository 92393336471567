import camelcaseKeys from 'camelcase-keys';
import {
  PRODUCT_BRANDS_FETCH_ERROR,
  PRODUCT_BRANDS_FETCH_START,
  PRODUCT_BRANDS_FETCH_SUCCESS,
  PRODUCT_COLORS_FETCH_ERROR,
  PRODUCT_COLORS_FETCH_START,
  PRODUCT_COLORS_FETCH_SUCCESS,
  PRODUCT_DETAIL_CLEAR_SUCCESS,
  PRODUCT_DETAIL_FETCH_ERROR,
  PRODUCT_DETAIL_FETCH_START,
  PRODUCT_DETAIL_FETCH_SUCCESS,
  PRODUCT_KEYWORD_CLEAR_TITLE,
  PRODUCT_KEYWORD_SAVE_TITLE,
  PRODUCT_LIST_CLEAN,
  PRODUCT_LIST_FETCH_ERROR,
  PRODUCT_LIST_FETCH_START,
  PRODUCT_LIST_FETCH_SUCCESS,
  PRODUCT_LIST_REFRESH_PROPS,
  PRODUCT_SIZES_FETCH_ERROR,
  PRODUCT_SIZES_FETCH_START,
  PRODUCT_SIZES_FETCH_SUCCESS,
  PRODUCT_TAGS_FETCH_ERROR,
  PRODUCT_TAGS_FETCH_START,
  PRODUCT_TAGS_FETCH_SUCCESS,
  PRODUCT_UPDATE_MAIN_SKU,
  PRODUCT_UPDATE_PRICE,
} from '../ActionTypes';
import {onChangeProductSKU} from '../../lib/product';

const initState = {
  // controller: new AbortController(),
  productList: null,
  product: null,
  productCount: 0,
  loading: false,
  error: '',
  colors: null,
  sizes: null,
  brands: null,
  tags: null,
  searchTitle: null,

  productListFetchId: null,
  productDetailFetchId: null,
  colorsFetchId: null,
  sizesFetchId: null,
  brandsFetchId: null,
  tagsFetchId: null,
};

const productReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case PRODUCT_DETAIL_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        productDetailFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_LIST_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        productListFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_COLORS_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        colorsFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_SIZES_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        sizesFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_BRANDS_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        brandsFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_TAGS_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
        tagsFetchId: payload.fetchId,
        // controller: payload ? payload.controller : null,
      };

    case PRODUCT_DETAIL_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        productDetailFetchId: null,
      };

    case PRODUCT_LIST_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        productListFetchId: null,
      };

    case PRODUCT_COLORS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        colorsFetchId: null,
      };

    case PRODUCT_SIZES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sizesFetchId: null,
      };

    case PRODUCT_BRANDS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        brandsFetchId: null,
      };

    case PRODUCT_TAGS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        tagsFetchId: null,
      };

    case PRODUCT_LIST_FETCH_SUCCESS:
      if (!payload.ssr && state.productListFetchId && state.productListFetchId !== payload.fetchId) {
        // console.log(`SKIPPED LIST_FETCH: STATE ${state.productListFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        productList: payload.data.results.map((product) => ({...product, mainSku: product.sku})),
        productCount: payload.data.count,
        loading: false,
        error: '',
        productListFetchId: null,
      };

    case PRODUCT_DETAIL_FETCH_SUCCESS:
      if (!payload.ssr && state.productDetailFetchId && state.productDetailFetchId !== payload.fetchId) {
        // console.log(`SKIPPED DETAIL_FETCH: STATE ${state.productDetailFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        product: {...payload.data, mainSku: payload.data.sku},
        loading: false,
        error: '',
        productDetailFetchId: null,
      };

    case PRODUCT_COLORS_FETCH_SUCCESS:
      if (!payload.ssr && state.colorsFetchId && state.colorsFetchId !== payload.fetchId) {
        // console.log(`SKIPPED COLORS_FETCH: STATE ${state.colorsFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        colors: payload.data,
        loading: false,
        error: '',
        colorsFetchId: null,
      };

    case PRODUCT_SIZES_FETCH_SUCCESS:
      if (!payload.ssr && state.sizesFetchId && state.sizesFetchId !== payload.fetchId) {
        // console.log(`SKIPPED SIZES_FETCH: STATE ${state.sizesFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        sizes: payload.data,
        loading: false,
        error: '',
        sizesFetchId: null,
      };

    case PRODUCT_BRANDS_FETCH_SUCCESS:
      if (!payload.ssr && state.brandsFetchId && state.brandsFetchId !== payload.fetchId) {
        // console.log(`SKIPPED BRANDS_FETCH: STATE ${state.brandsFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        brands: payload.data,
        loading: false,
        error: '',
        brandsFetchId: null,
      };

    case PRODUCT_TAGS_FETCH_SUCCESS:
      if (!payload.ssr && state.tagsFetchId && state.tagsFetchId !== payload.fetchId) {
        // console.log(`SKIPPED TAGS_FETCH: STATE ${state.tagsFetchId}----PAYLOAD ${payload.fetchId}`);
        return state;
      }
      return {
        ...state,
        tags: payload.data,
        loading: false,
        error: '',
        tagsFetchId: null,
      };

    case PRODUCT_UPDATE_MAIN_SKU:
      return {
        ...state,
        product:
          state.product && state.product.id === payload.productId
            ? onChangeProductSKU(state.product, payload.skuData)
            : state.product,
        productList:
          state.productList &&
          state.productList.map((product) =>
            product.id === payload.productId ? onChangeProductSKU(product, payload.skuData) : product,
          ),
      };

    case PRODUCT_DETAIL_CLEAR_SUCCESS:
      return {
        ...state,
        product: null,
      };

    case PRODUCT_LIST_CLEAN:
      return {
        ...state,
        productList: null,
        productCount: 0,
        colors: payload && payload.includes('colors') ? state.colors : null,
        sizes: payload && payload.includes('sizes') ? state.sizes : null,
        brands: payload && payload.includes('brands') ? state.brands : null,
        tags: payload && payload.includes('tags') ? state.tags : null,
      };

    case PRODUCT_LIST_REFRESH_PROPS:
      if (!payload) {
        return state;
      }
      return {
        ...state,
        productList: payload.pageProps.initialState.productData.productList,
        productCount: payload.pageProps.initialState.productData.productCount,
        colors: payload.pageProps.initialState.productData.colors,
        sizes: payload.pageProps.initialState.productData.sizes,
        brands: payload.pageProps.initialState.productData.brands,
        tags: payload.pageProps.initialState.productData.tags,
      };

    case PRODUCT_KEYWORD_SAVE_TITLE:
      return {
        ...state,
        searchTitle: payload,
      };

    case PRODUCT_KEYWORD_CLEAR_TITLE:
      return {
        ...state,
        searchTitle: null,
      };

    case PRODUCT_UPDATE_PRICE:
      return {
        ...state,
        product:
          state.product && state.product.id === payload.productId
            ? {
                ...state.product,
                sku: {
                  ...state.product.sku,
                  stock: payload.skuId === state.product.sku.id ? payload.quantity : state.product.sku.stock,
                  price: payload.skuId === state.product.sku.id ? payload.price : state.product.sku.price,
                },
                mainSku: {
                  ...state.product.mainSku,
                  stock: payload.skuId === state.product.mainSku.id ? payload.quantity : state.product.mainSku.stock,
                  price: payload.skuId === state.product.mainSku.id ? payload.price : state.product.mainSku.price,
                },
              }
            : state.product,
        productList:
          state.productList &&
          state.productList.map((product) =>
            product.id === payload.productId
              ? {
                  ...product,
                  sku: {
                    ...product.sku,
                    stock: payload.skuId === product.sku.id ? payload.quantity : product.sku.stock,
                    price: payload.skuId === product.sku.id ? payload.price : product.sku.price,
                  },
                  mainSku: {
                    ...product.mainSku,
                    stock: payload.skuId === product.mainSku.id ? payload.quantity : product.mainSku.stock,
                    price: payload.skuId === product.mainSku.id ? payload.price : product.mainSku.price,
                  },
                }
              : product,
          ),
      };

    default:
      return state;
  }
};

export default productReducer;
