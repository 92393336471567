import camelcaseKeys from 'camelcase-keys';
import {BRAND_LIST_FETCH_ERROR, BRAND_LIST_FETCH_START, BRAND_LIST_FETCH_SUCCESS} from '../ActionTypes';

const initState = {
  brandList: null,
  loading: false,
  error: '',
};

const brandReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case BRAND_LIST_FETCH_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case BRAND_LIST_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BRAND_LIST_FETCH_SUCCESS:
      return {
        ...state,
        brandList: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default brandReducer;
