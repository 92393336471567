import {DIRECT_ORDER_CLEAN} from '../ActionTypes';
import {reduceProductData} from '../../lib/product';

export const ADD_TO_CART = 'ADD_TO_CART';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_ALL_FROM_CART = 'DELETE_ALL_FROM_CART';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize,
  selectedProductSize2,
) => {
  return (dispatch) => {
    dispatch({type: DIRECT_ORDER_CLEAN});
    if (addToast) {
      addToast('Added To Cart', {appearance: 'success', autoDismiss: true});
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...reduceProductData(item),
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null,
        selectedProductSize2: selectedProductSize2
          ? selectedProductSize2
          : item.selectedProductSize2
          ? item.selectedProductSize2
          : null,
      },
    });
  };
};

export const updateCartItems = (cartItems) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CART_ITEMS,
      payload: cartItems,
    });
  };
};

//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return (dispatch) => {
    dispatch({type: DIRECT_ORDER_CLEAN});
    if (addToast) {
      addToast('Item Decremented From Cart', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
    dispatch({type: DECREASE_QUANTITY, payload: item});
  };
};

//delete from cart
export const deleteFromCart = (item, addToast) => {
  return (dispatch) => {
    dispatch({type: DIRECT_ORDER_CLEAN});
    if (addToast) {
      addToast('Removed From Cart', {appearance: 'error', autoDismiss: true});
    }
    dispatch({type: DELETE_FROM_CART, payload: item});
  };
};

//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return (dispatch) => {
    dispatch({type: DIRECT_ORDER_CLEAN});
    if (addToast) {
      addToast('Removed All From Cart', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    dispatch({type: DELETE_ALL_FROM_CART});
  };
};
