import React, {Fragment} from 'react';
import Head from 'next/head';
import {ToastProvider} from 'react-toast-notifications';
import InfoView from '../components/core/InfoView';
import Core from './_core';
import {wrapper} from '../redux/store';
import {UserProvider} from '@auth0/nextjs-auth0';
import SSRProvider from 'react-bootstrap/SSRProvider';
import {PersistGate} from 'redux-persist/integration/react';
import {useStore} from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import '../assets/scss/styles.scss';

const WrappedApp = ({Component, pageProps}) => {
  // https://github.com/kirill-konshin/next-redux-wrapper
  const store = useStore();

  return (
    <Fragment>
      <Head>
        <title>ValetPress, Inc. &#8211; Complete Business Garment Care. Delivered!</title>
        <link rel='icon' href={process.env.NEXT_PUBLIC_URL + '/favicon.ico'} />
        {/*<meta name='viewport' content='user-scalable=no' />*/}
      </Head>
      <SSRProvider>
        <CookieConsent
          location='bottom'
          buttonText='ACCEPT COOKIES'
          cookieName='valetpress'
          containerClasses='cookie-consent'
          // style={{backgroundColor: '#e8f0f8', color: '#000000', padding: '30px 100px 30px 100px'}}
          buttonStyle={{
            color: '#000000',
            fontSize: '13px',
            padding: '15px 20px 15px 20px',
            backgroundColor: '#ffffff',
            border: '1px solid #000000',
          }}
          expires={150}>
          This website uses cookies to enhance the user experience. Please see our{' '}
          <a href='https://valetpress.com/privacy-policy/' style={{textDecoration: 'underline'}}>
            Privacy Policy.
          </a>
        </CookieConsent>
        <PersistGate loading={null} persistor={store.__persistor}>
          {() => (
            <UserProvider>
              <ToastProvider placement='bottom-left'>
                <Core>
                  <Component {...pageProps} />
                  <InfoView />
                </Core>
              </ToastProvider>
            </UserProvider>
          )}
        </PersistGate>
      </SSRProvider>
    </Fragment>
  );
};

export default wrapper.withRedux(WrappedApp);
