import camelcaseKeys from 'camelcase-keys';
import {
  CLEANER_LOCATION_CLEAR_LIST_ALL,
  CLEANER_LOCATION_GET_LIST_ALL_ERROR,
  CLEANER_LOCATION_GET_LIST_ALL_START,
  CLEANER_LOCATION_GET_LIST_ALL_SUCCESS,
} from '../ActionTypes';

const initState = {
  cleanerLocationListAll: null,
  loading: false,
  error: '',
};

const cleanerLocationReducer = (state = initState, action) => {
  let payload = action.payload;
  if (payload) payload = camelcaseKeys(payload, {deep: true});

  switch (action.type) {
    case CLEANER_LOCATION_GET_LIST_ALL_START:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case CLEANER_LOCATION_GET_LIST_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEANER_LOCATION_GET_LIST_ALL_SUCCESS:
      return {
        ...state,
        cleanerLocationListAll: payload,
        loading: false,
        error: '',
      };

    case CLEANER_LOCATION_CLEAR_LIST_ALL:
      return {
        ...state,
        cleanerLocationListAll: null,
      };

    default:
      return state;
  }
};

export default cleanerLocationReducer;
